





























































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class AddBusinessModel extends Vue {
  public name: string = "";
  public description: string = "";
  public type: string = "";
  public fee: number = 0;
  public paypercall: string = "";
  public paypermonth: string = "";
  public price: number = 0;

  public submit() {
    // dummy
  }
}
