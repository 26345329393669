




import { Component, Prop, Vue } from "vue-property-decorator";
import SwaggerUI from "swagger-ui";
import { Spec } from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";

@Component
export default class SwaggerDisplay extends Vue {
  @Prop()
  public openApiUrl: string;

  @Prop()
  public openApiDocument: Spec;

  public mounted() {
    const ui = SwaggerUI({
      url:this.openApiUrl,
      spec: this.openApiDocument,
      dom_id: "#swagger",
    });
  }
}
