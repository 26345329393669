

























































































































import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import ServicesList from "@/components/ServicesList.vue";
import { Inject } from "inversify-props";
import {
  IServicesClient,
  ServiceDto,
  ProductDto,
  IProductsClient,
} from "@/services/CloudNuggetsApiClient";
import PriceInfoDialog from "@/components/PriceInfoDialog.vue";
import { PriceInfoDialogData } from "@/components/PriceInfoDialog.vue";
import { IPcfService, IPcf } from "@/services/PcfService";

@Component({
  components: {
    PriceInfoDialog,
  },
})
export default class ServiceProductSelector extends Vue {

  public loading = { services: false };
  public services: SelectedService[] = [];
  public searchTerm: string = "";
  public valueLocal: ServiceProduct[] = [];
  public priceInfoData: PriceInfoDialogData = null;

  private loadedServices: ServiceDto[] = null;

  @Model("change")
  public value!: ServiceProduct[];

  @Prop()
  public alreadyUsedServices: string[];

  @Prop({ type: Boolean, default: false })
  public multiple: boolean = false;

  @Inject()
  private servicesClient: IServicesClient;
  @Inject()
  private productsClient: IProductsClient;
  @Inject()
  private pcfService: IPcfService;

  @Watch("value")
  private onValueChange(newValue: ServiceProduct[]){
    this.updateSelected();
  }

  public get selectedServiceEntityIds() {
    return this.valueLocal.map(x => x.serviceEntityId);
  }

  public get filteredServices() {
    if (!this.searchTerm || !this.services) {
      return this.services;
    }

    return this.services.filter(
      (x) => x.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
    );
  }

  public getIsServiceSelected(serviceEntityId: string){
    return this.selectedServiceEntityIds.some(x => x === serviceEntityId);
  }

  public get selectedOrAlreadyUsedServices(): string[] {
    return [...this.valueLocal.map(x => x.serviceEntityId), ...(this.alreadyUsedServices || [])];
  }

  public showPriceInfoDialog(serviceEntityId: string, productKey: string) {
    this.priceInfoData = {
      serviceEntityId: serviceEntityId,
      productKey: productKey,
      visible: true,
    };
  }

  public getMinPriceTextByKey(serviceEntityId: string, productKey: string): string {
    let product = this.services
      .find(x => x.entityId === serviceEntityId)
      .products
      .find(x => x.productid == productKey);

    return this.getMinPriceText(product);
  }

  public getMinPriceText(product: ProductDto): string {
    if (!product ||
      !product.pcf) {
      return "0 EUR";
    }

    let pcf = this.pcfService.parse(product.pcf);

    let costInfo = this.pcfService.getMinCostInfo(pcf);
    if (!costInfo) {
      return "0 EUR";
    }

    return `From ${costInfo.amount}${costInfo.currency}${
      !!costInfo.chargeUnit ? ` (per ${costInfo.chargeUnit})` : ""
    }`;
  }

  public selectServiceProduct(service: SelectedService, product: SelectedProduct) {
    let productKey = product && product.productid || null;
    if (product) {
      product.selected = true;
    }

    let services = this.valueLocal || [];

    let serviceProduct = services.find(x => x.serviceEntityId === service.entityId && x.productKey == productKey);
    if (!serviceProduct) {
      serviceProduct = new ServiceProduct(service.entityId, service.name, service.description, productKey, product && product.name, product && product.description, product && this.pcfService.parse(product.pcf));
      services = [...services, serviceProduct];
    } 

    this.valueLocal = services;

    this.$emit("change", services);
  }

  public unselectServiceProduct(serviceEntityId: string, productKey: string) {
    let product = this.services.find(x => x.entityId === serviceEntityId).products.find(x => x.productid == productKey);
    if (product) {
      product.selected = false;
    }

    let services = this.valueLocal || [];
    let serviceProduct = services.find(x => x.serviceEntityId === serviceEntityId && x.productKey == productKey);
    if (serviceProduct) {
      services = services.filter(x => x != serviceProduct);
    } 

    this.valueLocal = services;

    this.$emit("change", services);
  }

  private async loadProducts(service: SelectedService) {
    if (service.products && service.products.length > 0) {
      return;
    }

    service.loading.products = true;
    service.products = (await this.productsClient.getProducts(service.entityId))
      .map(x => new SelectedProduct(x));
    
    if (service.products.length === 0) {
      let freeProduct = new SelectedProduct(null);
      freeProduct.name = "Free Product";
      freeProduct.description = "Provided to you for free";
      service.products.push(freeProduct);
    }
    service.loading.products = false;
  }

  private created() {
    this.load();
  }

  private async load() {
    this.loading.services = true;
    try {
    this.loadedServices = await this.servicesClient.getReleasedServices();
    this.services = this.loadedServices
      .map((x) => new SelectedService(x))
      .map((x) => {
        x.selected = !!this.value.find((y) => y.serviceName == x.name);
        return x;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    } catch(error) {
      console.error(error);
    } finally {
      this.loading.services = false;
    }
  }

  private updateSelected(){
    this.services
      .map((x) => {
        x.selected = !!this.value.find((y) => y.serviceName == x.name);
        for(let p of x.products){
          if(!x.selected) {
            p.selected = false;
          } else {
            p.selected = !!this.value.find((y) => y.serviceName == x.name && y.productKey == p.productid);
          }
        }
        return x;
      });
  }
}

class SelectedService extends ServiceDto {
  public selected: boolean = false;
  public expanded: boolean = false;
  public loading = { products: false };

  public products: SelectedProduct[] = [];

  constructor(service: ServiceDto) {
    super(service);
  }
}

class SelectedProduct extends ProductDto{
  public legalAcknowleged = false;
  public selected = false;

  constructor(product: ProductDto){
    super(product);
  }
}

export class ServiceProduct {
  public constructor(public serviceEntityId: string,
  public serviceName: string,
  public serviceDescription: string,
  public productKey: string,
  public productName: string,
  public productDescription: string,
  public productPcf: IPcf) {

  }
}
