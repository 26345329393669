import { API_ENDPOINT, getToken } from '@/services/Environment';
import axios from 'axios';

const getEndpoint = (path: string) => {
  return `${API_ENDPOINT}${path}`;
};

const getHeaders = () => {
  const headers: any = {};
  const token = getToken();

  if (token) {
    headers.cloudnuggetjwttoken = token;
  }
  return headers;
};

const get = (path: string) => {
  const endpoint = getEndpoint(path);
  const headers = getHeaders();

  return axios.get(endpoint, {
    headers
  });
};

const post = (path: string, data: any) => {
  const endpoint = getEndpoint(path);
  const headers = getHeaders();

  return axios.post(endpoint, data, {
    headers
  });
};

const put = (path: string, data: any) => {
  const endpoint = getEndpoint(path);
  const headers = getHeaders();

  return axios.put(endpoint, data, {
    headers
  });
};

const del = (path: string) => {
  const endpoint = getEndpoint(path);
  const headers = getHeaders();

  return axios.delete(endpoint, {
    headers
  });
};
export { axios };
export default { getHeaders, axios };