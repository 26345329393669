











import { Component, Vue } from "vue-property-decorator";
import UserList from "@/components/UserList.vue";

@Component({
  components: {
    UserList,
  },
})
export default class Users extends Vue {
  public headers: any[] = [
    { text: "ID", value: "id" },
    { text: "User Pool Id", value: "userPoolId" },
    { text: "Role Id", value: "userRoleId" },
    { text: "First Name", value: "firstName" },
    { text: "Last Name", value: "lastName" },
    { text: "Language", value: "language" },
    { text: "Delete", value: "deletedDate" },
  ];

  public properties: any[] = [
    { name: "First Name", key: "firstName", type: "string" },
    { name: "Last Name", key: "lastName", type: "string" },
    { name: "User Name", key: "userName", type: "string" },
    { name: "User Pool", key: "userPoolId", type: "string" },
    { name: "Language", key: "language", type: "string" },
  ];
}
