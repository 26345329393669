





































































































































































import { Component, Vue } from "vue-property-decorator";
import { axios } from "@/services/HttpService";
import {
  IAppsClient,
  IServicesClient,
  AppDto,
} from "@/services/CloudNuggetsApiClient";
import { API_ENDPOINT } from "@/services/Environment";
import { Inject } from "inversify-props";

/*
document.addEventListener( 'click', function copyFunction() {
   var copyText = document.getElementById("myInput")!.innerText;
   var element = document.createElement("textarea");
   console.log(copyText);
   element.value = copyText;
   element.select();
   document.execCommand("copy");

  })
*/

@Component({
  components: {},
})
export default class AddApp extends Vue {
  // routeapps: string = "/apps";
  // routeservices: string = "/services";
  app: AppDto = null;
  isLoading: boolean = false;
  dialog: boolean = false;

  //    copyFunction(copyText:string) {

  //    var element = document.createElement("textarea");

  //    element.value = copyText;
  //    element.select();
  //    var result = document.execCommand("copy");

  // navigator.permissions.query({name: "clipboard-write"}).then(result => {
  //   if (result.state == "granted" || result.state == "prompt") {
  //     navigator.clipboard.writeText(copyText).then(function() {
  //     /* clipboard successfully set */
  //   }, function() {
  //     /* clipboard write failed */
  //   });
  //   }
  // });

  //   }

  @Inject()
  private appsClient: IAppsClient;
  @Inject()
  private servicesClient: IServicesClient;

  async created() {
    await this.load();
  }

  async load() {
    this.isLoading = true;

    //apps request
    try {
      // const response = await get(this.$route.params.id);
      // this.items = response.data;
      this.app = await this.appsClient.getApp(
        /* entityId */ this.$route.params.id
      );
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  }
  // data() {
  //   return {
  //     dialog: false,
  //   };
  // }
}
