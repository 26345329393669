const env = (process.env.NODE_ENV as string).trim();

import { ENVIRONMENT } from '@/services/Config';

let endpoint = 'https://api.frontend.master.cloudnuggets.io';

if (ENVIRONMENT === 'develop') {
  // endpoint = 'http://localhost:17320'
  endpoint = 'https://localhost:44352';
} else if (ENVIRONMENT === 'staging') {
  endpoint = 'https://api.frontend.staging.cloudnuggets.io';
} else if (ENVIRONMENT === 'master') {
  endpoint = 'https://api.frontend.master.cloudnuggets.io';
}

const API_ENDPOINT = endpoint;
const CLOUDNUGGETS_TOKEN = 'cloudnuggets_token';

const getToken = () => {
  return localStorage.getItem(CLOUDNUGGETS_TOKEN);
};

const setToken = (token: string) => {
  localStorage.setItem(CLOUDNUGGETS_TOKEN, token);
};

const clearToken = () => {
  localStorage.removeItem(CLOUDNUGGETS_TOKEN);
};

export { API_ENDPOINT, getToken, setToken, clearToken };