import retry from './retry';

export interface IToolbarItem {

}
export interface IAppbarService {
    updateToolbarItems(items: IToolbarItem[]): void;
    updatePageTitle(title: string): void;
}

export class AppbarService implements IAppbarService {
    constructor(private appFactory: () => Vue) { }

    updateToolbarItems(items: IToolbarItem[]): void  {        
        retry(() => this.appFactory().$root.$emit("update:pageToolbarItems", items), 20);
    }

    updatePageTitle(title: string): void {
        retry(() => this.appFactory().$root.$emit("update:pageTitle", title), 20);
    }

   
}