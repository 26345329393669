




import { Component, Prop, Vue } from "vue-property-decorator";
import marked from "marked";

@Component
export default class MarkdownDisplay extends Vue {
  public get processedMarkdown() {
    return marked(this.markdown);
  }

  @Prop()
  public markdown: string;
}
