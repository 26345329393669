












































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Inject } from "inversify-props";
import { IProductsClient, ProductDto } from "@/services/CloudNuggetsApiClient";

@Component({
  components: {},
})
export default class PriceInfoDialog extends Vue {
  @Prop()
  data!: PriceInfoDialogData;

  public product: ProductDto = null;
  public pcf: any = null;

  private loading = { product: false };

  @Inject()
  private productsClient: IProductsClient;

  async created() {
    await this.loadPriceInfo();
  }

  private async loadPriceInfo() {
    if (!this.data ||
      !this.data.serviceEntityId) {
      return;
    }

    this.loading.product = true;
    
    this.product = null;
    this.pcf = null;

    this.data.visible = true;

    try {
      let products = await this.productsClient.getProducts(
        this.data.serviceEntityId
      );
      this.product = products.filter(
        (x) => x.productid === this.data.productKey
      )[0];
      this.pcf = JSON.parse(this.product.pcf);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading.product = false;
    }
  }

  @Watch("data.visible")
  private onDataChanged(newValue: boolean, oldValue: boolean) {
    if (newValue) {
      this.loadPriceInfo();
    }
  }
}

export class PriceInfoDialogData {
  serviceEntityId!: string;
  productKey!: string;
  visible!: boolean;
}
