



























































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { clearToken, getToken } from "@/services/Environment";
import { routes } from "@/router";
import { ENVIRONMENT } from '@/services/Config';
import { Route } from 'vue-router';

@Component({
  components: {},
})
export default class Navigation extends Vue {
  public drawer: boolean | null = null;
  public mini: boolean = true;
  public isAuthenticated: boolean = false;

  public items = <any[]>routes[0].children;
  public title: string = null;
  public pageToolbarItems: any[] = [];

  get availableItems() {
    const list = this.items
      .filter((x) => this.filterMenuItem(x))
      .map((x) => {
        x = { ...x };
        if (x.children) {
          x.children = (<any[]>x.children).filter((y) =>
            this.filterMenuItem(y)
          );
        }
        return x;
      });

    return list;
  }

  private filterMenuItem(item: any) {
    return (
      item.meta &&
      item.meta.title &&
      !item.meta.hideMenu &&
      this.validOnCurrentStage(item) &&
      (!item.meta.requiresAuthentication || this.isAuthenticated)
    );
  }

  private validOnCurrentStage(to: any): boolean {
    let requirementNotMet = to.meta && to.meta.stages && !to.meta.stages.find((stage: string) => stage === ENVIRONMENT);
    return !requirementNotMet;
  }

  created() {
    this.$root.$on("login", () => {
      this.isAuthenticated = true;
    });
    this.$root.$on("logout", () => {
      this.isAuthenticated = false;
    });

    this.isAuthenticated = !!getToken();
  }

  private buildTitle(pageTitle: string) {
    let title: string =
      (this.$route.meta &&
        (this.$route.meta.pageTitle || this.$route.meta.title)) ||
      "Cloud Nuggets";

    if (title.indexOf("{{pageTitle}}") != -1) {
      if (pageTitle) {
        title = title.replace("{{pageTitle}}", pageTitle);
      } else {
        title = null;
      }

      if (!title) {
        title = this.$route.meta.pageTitleFallback || "Cloud Nuggets";
      }
    }

    return title;
  }

  mounted() {
    this.$router.afterEach((to, from) => {
      this.title = this.buildTitle(null);
      this.pageToolbarItems = [];
    });

    this.$root.$on("update:pageTitle", (pageTitle: string) => {
      this.title = this.buildTitle(pageTitle);      
    });
    this.$root.$on("update:pageToolbarItems", (items: any[]) => {
      this.pageToolbarItems = items;
    });
  }

  logout() {
    clearToken();
    this.$root.$emit("logout");
    this.$router.push("/");
  }

  login() {
    this.$router.push("/login");
  }
}
