import { container, inject, cid } from 'inversify-props';
import "reflect-metadata";

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import clipboard from './plugins/v-clipboard';
import vueMoment from './plugins/vue-moment';
import { IAppsClient, AppsClient, IBillingClient, BillingClient, ICloudNuggetClient, CloudNuggetClient, IAccountClient, AccountClient, IDevicePoolsClient, DevicePoolsClient, IDevicesClient, DevicesClient, SupportClient, ISupportClient, IProcessClient, ProcessClient, IProductsClient, ProductsClient, IServicesClient, ServicesClient, IUserPoolsClient, UserPoolsClient, IUsersClient, UsersClient } from './services/CloudNuggetsApiClient';
import { API_ENDPOINT } from './services/Environment';
import { axios } from './services/HttpService';
import AxiosConfig from './axiosConfig';
import { PcfService, IPcfService } from './services/PcfService';
import { INotificationService, NotificationService } from './services/NotificationService';
import { AppbarService, IAppbarService } from './services/AppbarService';


container.addSingleton<IAccountClient>(function () { return new AccountClient(API_ENDPOINT, axios); }, "AccountClient");
container.addSingleton<IAppsClient>(function () { return new AppsClient(API_ENDPOINT, axios); }, "AppsClient");
container.addSingleton<IBillingClient>(function () { return new BillingClient(API_ENDPOINT, axios); }, "BillingClient");
container.addSingleton<ICloudNuggetClient>(function () { return new CloudNuggetClient(API_ENDPOINT, axios); }, "CloudNuggetClient");
container.addSingleton<IDevicePoolsClient>(function () { return new DevicePoolsClient(API_ENDPOINT, axios); }, "DevicePoolsClient");
container.addSingleton<IDevicesClient>(function () { return new DevicesClient(API_ENDPOINT, axios); }, "DevicesClient");
container.addSingleton<ISupportClient>(function () { return new SupportClient(API_ENDPOINT, axios); }, "SupportClient");
container.addSingleton<IProcessClient>(function () { return new ProcessClient(API_ENDPOINT, axios); }, "ProcessClient");
container.addSingleton<IProductsClient>(function () { return new ProductsClient(API_ENDPOINT, axios); }, "ProductsClient");
container.addSingleton<IServicesClient>(function () { return new ServicesClient(API_ENDPOINT, axios); }, "ServicesClient");
container.addSingleton<IUserPoolsClient>(function () { return new UserPoolsClient(API_ENDPOINT, axios); }, "UserPoolsClient");
container.addSingleton<IUsersClient>(function () { return new UsersClient(API_ENDPOINT, axios); }, "UsersClient");
container.addSingleton<IPcfService>(function () { return new PcfService(); }, "PcfService");
container.addSingleton<INotificationService>(function () { return new NotificationService(() => app); }, "NotificationService");
container.addSingleton<IAppbarService>(function () { return new AppbarService(() => app); }, "AppbarService");

Vue.config.productionTip = false;

AxiosConfig.configure();
// dummy assignment to prevent being removed by tree-shaking

clipboard.install();
vueMoment.install();

let app = new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');