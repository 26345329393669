import { getToken } from "@/services/Environment";
import { axios } from "@/services/HttpService";
import router from "./router";

export default class AxiosConfig {
  private static suppressLoginTimestamp: Date = null;

  private static anonymousRoutes: RegExp[] = [
    new RegExp("/cloud-nugget"),
    new RegExp("/services/released"),
    new RegExp("/account/register"),
    new RegExp("/account/reset"),
    new RegExp("\/users\/.*?\/.*?\/confirm"), // /users/{appEntityId}/{email}/confirm
    new RegExp("​\/users\/.*?\/.*?\/changepassword\/.*?"), // ​/users​/{appEntityId}​/{email}​/changepassword​/{secret}
    new RegExp("\/users\/.*?\/.*?\/reset") // ​/users​/{appEntityId}​/{email}​/reset
  ];

  static configure() {
    // inject token if available
    axios.interceptors.request.use(async (requestConfig) => {

      // workaround: inject token only on non-anonymous routes
      const isAnonmyousRoute = this.anonymousRoutes.some(x => x.test(requestConfig.url));
      if (!isAnonmyousRoute) {
        var token = getToken();
        requestConfig.headers.cloudnuggetjwttoken = token;
      }

      return requestConfig;
    });

    // redirect to login if API call is not authorized
    axios.interceptors.response.use(undefined, async (err) => {
      if (
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        // if you ever get an unauthorized, logout the user
        //this.$store.dispatch(AUTH_LOGOUT)
        if (this.canShowAgainLoginPrompt()) {
          this.suppressLoginTimestamp = new Date();
          if (
            confirm(
              "Sie sind nicht berechtigt.\n\rWollen Sie sich unter einem anderen berechtigten Benutzer anmelden?"
            )
          ) {
            router.push("/login?redirect_to=" + router.currentRoute.fullPath);
          } else {

          }
        }
        // you can also redirect to /login if needed !
      }
      throw err;
    });


  }

  private static canShowAgainLoginPrompt(): boolean {
    return !this.suppressLoginTimestamp || // never shown
      this.suppressLoginTimestamp < new Date(new Date().getTime() - 5 * 1000); // 5 seconds back-off
  }
}
