














































































































import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import ServiceProductSelector, { ServiceProduct } from "@/components/ServiceProductSelector.vue";
import { Inject } from "inversify-props";
import {
  IServicesClient,
  ServiceDto,
  IAppsClient,
  AppDto,
} from "@/services/CloudNuggetsApiClient";
import PriceInfoDialog, { PriceInfoDialogData } from '@/components/PriceInfoDialog.vue';
import { IPcfService, IPcf } from '@/services/PcfService';
import { IAppbarService } from '@/services/AppbarService';

@Component({
  components: {
    ServiceProductSelector,
    PriceInfoDialog
  },
})
export default class AddServiceToApp extends Vue {
  private _pageTitle: string = null;
  set pageTitle(value: string) {
    this._pageTitle = value;
    this.appbarService.updatePageTitle(this._pageTitle);
  }
  get pageTitle() {
    return this._pageTitle;
  }

  private get pageToolbarItems(): any[] {
    return [];
  }

  public loading = { services: false };
  public selectedServices: ServiceProduct[] = [];
  public alreadyUsedServices: string[] = null;
  public showErrorSnackbar: boolean = false;
  public errorMessage: string = null;
  public priceInfoData: PriceInfoDialogData = null;

  public sheet: boolean = false;

  @Inject()
  private servicesClient: IServicesClient;

  @Inject()
  private pcfService: IPcfService;

  private app: AppDto;

  @Inject()
  private appsClient: IAppsClient;

  @Inject()
  private appbarService: IAppbarService;

  public get addServiceEnabled() {
    return !!this.selectedServices && this.selectedServices.length;
  }

  @Watch("addServiceEnabled")
  private addServiceEnabledChanged() {
    this.updatePageToolbarItems();
  }

  @Watch("selectedServices")
  private selectedServicesChanged(newServiceProducts: ServiceProduct[], oldServiceProducts: ServiceProduct[]) {
    if (!newServiceProducts ||
      !oldServiceProducts) {
        return;
    }

    this.sheet = newServiceProducts.length > oldServiceProducts.length ? true : this.sheet;
  }

  public unselectServiceProduct(serviceProduct: ServiceProduct){
    let i = this.selectedServices.indexOf(serviceProduct);
    this.selectedServices.splice(i, 1);

    this.sheet = this.selectedServices.length > 0;
  }

  public async addSelectedServices() {    
    console.dir(this.selectedServices);

    this.showErrorSnackbar = false;
    this.errorMessage = "";

    for (let service of this.selectedServices) {
      try {
        await this.appsClient.addAppService(this.app.entityId, service.serviceEntityId, service.productKey);
        
      } catch (error) {
        console.error(error);
        this.errorMessage += `Could not add ${service.serviceName} (${service.productKey || 'FREE' })<br/>`;
        this.showErrorSnackbar = true;
      }
    }

    if (!this.showErrorSnackbar) {
      this.$router.push({ name: "app-details", params: { id: this.app.entityId } });
    }
  }

  

  public showPriceInfoDialog(serviceEntityId: string, productKey: string) {
    this.priceInfoData = {
      serviceEntityId: serviceEntityId,
      productKey: productKey,
      visible: true,
    };
  }

  public getMinPriceTextByKey(selectedService: ServiceProduct): string {
    return this.getMinPriceText(selectedService.productPcf);
  }

  public getMinPriceText(pcf: IPcf): string {
    if (!pcf) {
      return "0 EUR";
    }

    let costInfo = this.pcfService.getMinCostInfo(pcf);
    if (!costInfo) {
      return "0 EUR";
    }

    return `From ${costInfo.amount}${costInfo.currency}${
      !!costInfo.chargeUnit ? ` (per ${costInfo.chargeUnit})` : ""
    }`;
  }


  private updatePageToolbarItems(){
    this.appbarService.updateToolbarItems(this.pageToolbarItems);
  }

  async mounted() {
    this.updatePageToolbarItems();

    try {
      this.app = await this.appsClient.getApp(this.$route.params.id);
      if (!this.app) {
        throw "App not found";
      }
      
      this.pageTitle = this.app.name;

      let appServices = await this.appsClient.getAppServices(this.app.entityId);
      this.alreadyUsedServices = appServices.map(x => x.entityId);
    } catch (error) {
      console.error(error);
    }
  }
}
