















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import MarkdownDisplay from "@/components/MarkdownDisplay.vue";
import SwaggerDisplay from "@/components/SwaggerDisplay.vue";
import { StatusIndicator } from "vue-status-indicator";
import {
  IServicesClient,
  ServiceDto,
  ICloudNuggetClient,
  CloudNuggetClient,
  PingDto,
  AppDto,
  IAppsClient,
  AppKeyDto,
  AppServiceDependencyDto,
  UpdateApp,
  IUserPoolsClient,
  ServiceDependencyDto,
  UpdateService,
  IProductsClient,
  ProductDto,
} from "@/services/CloudNuggetsApiClient";
import { API_ENDPOINT } from "@/services/Environment";
import { axios } from "@/services/HttpService";
import { Inject } from "inversify-props";
import PriceInfoDialog, { PriceInfoDialogData } from "@/components/PriceInfoDialog.vue";
import { IAppbarService } from '@/services/AppbarService';
import { INotificationService } from '@/services/NotificationService';

@Component({
  components: {
    MarkdownDisplay,
    SwaggerDisplay,
    StatusIndicator,
    PriceInfoDialog
  },
})
export default class ServiceDetails extends Vue {
  private _pageTitle: string = null;
  set pageTitle(value: string) {
    this._pageTitle = value;
    this.appbarService.updatePageTitle(this._pageTitle);
  }
  get pageTitle() {
    return this._pageTitle;
  }

  public service: ServiceDto = null;
  public services: ServiceDependencyDto[] = [];

  private pageToolbarItems: any[] = [
    {
      name: "Rename",
      icon: "mdi-pencil",
      tooltip: "Rename",
      action: () => this.showUpdateDialog(),
    },
  ];

  isUpdateDialogVisible: boolean = false;
  renameData = { title: "", name: "" };

  priceInfoData: PriceInfoDialogData = null;

  @Inject()
  private servicesClient: IServicesClient;
  
  @Inject()
  private appbarService: IAppbarService;
  
  @Inject()
  private notificationService: INotificationService;

  private loading = {
    service: false,
    secret: false,
    services: false,
    rename: false,
  };
  private entityId: string;

  public secretCopied() {
    this.notificationService.success("Secret copied to clipboard");
  }

  private async created() {
    this.entityId = this.$route.params.id;
    this.appbarService.updateToolbarItems(this.pageToolbarItems);

    await Promise.all([this.loadService(), this.loadServices()]);
  }

  public showUpdateDialog() {
    this.renameData = { title: this.service.name, name: this.service.name };
    this.isUpdateDialogVisible = true;
  }

  private async rename() {
    try {
      this.loading.rename = true;
      await this.servicesClient.updateService(
        this.entityId,
        new UpdateService({
          name: this.renameData.name,
          released: this.service.released,
        })
      );
      this.pageTitle = this.renameData.name;
      this.isUpdateDialogVisible = false;
    } catch (error) {
    } finally {
      this.loading.rename = false;
    }
  }

  public async showPriceInfoDialog(service: ServiceDependencyDto) {
    this.priceInfoData = { serviceEntityId: service.entityId, productKey: service.productKey, visible: true };
  }

   public async removeServiceDependendy(serviceDependency: AppServiceDependencyDto) {
    try {
      await this.servicesClient.deleteServiceDependency(this.service.entityId, serviceDependency.entityId);

      this.services.splice(this.services.indexOf(serviceDependency), 1);

      this.notificationService.success(`'${serviceDependency.name}' removed from '${this.service.name}'`);
    } catch (error) {
      console.error(error);

      this.notificationService.error(`Could not remove '${serviceDependency.name}' from '${this.service.name}'`);
    }
  }

  private async loadService() {
    this.loading.service = true;
    try {
      this.service = await this.servicesClient.getService(this.entityId);
      this.pageTitle = this.service.name;
    } finally {
      this.loading.service = false;
    }
  }

  private async loadServices() {
    this.loading.services = true;
    try {
      this.services = await this.servicesClient.getServiceDependencies(
        this.entityId
      );
    } finally {
      this.loading.services = false;
    }
  }
}
