import Vue from 'vue';
import Router, { Route } from 'vue-router';
import Home from '@/views/Home.vue';
import ServiceInfos from '@/views/ServiceInfos.vue';
import ServicesOverview from '@/views/ServicesOverview.vue';
import Dashboard from '@/views/admin/Dashboard.vue';
import ManageHome from '@/views/admin/ManageHome.vue';
import Services from '@/views/admin/Services.vue';
import Apps from '@/views/admin/Apps.vue';
import Users from '@/views/admin/Users.vue';
import UserPools from '@/views/admin/UserPools.vue';
import Devices from '@/views/admin/Devices.vue';
import ChangeApp from '@/views/admin/ChangeApp.vue';
import AddApp from '@/views/admin/AddApp.vue';
import AppDetails from '@/views/admin/AppDetails.vue';
import AddServiceToApp from '@/views/admin/AddServiceToApp.vue';
import AddServiceToService from '@/views/admin/AddServiceToService.vue';
import AddBusinessModel from '@/views/admin/AddBusinessModel.vue';
import ServiceDetails from '@/views/admin/ServiceDetails.vue';

import { getToken } from '@/services/Environment';
import { ENVIRONMENT } from './services/Config';
import { Stages } from './services/Stages';


Vue.use(Router);

let hideWorkInProgress = ENVIRONMENT != "develop";

export const routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'services-overview',
    component: Home,
    meta: { requiresAuthentication: false },
    children: [
      
      {
        path: 'services-overview',
        name: 'services-overview',
        component: ServicesOverview,
        meta: { requiresAuthentication: false, icon: "mdi-api", title: "Services Overview" }
      },
      {
        path: 'services-overview/:id',
        props: true,
        name: 'service-infos',
        component: ServiceInfos,
        meta: { requiresAuthentication: false, hideMenu: true, pageTitle: "Service {{pageTitle}}", pageTitleFallback: "Service ..." },
      },
      {
        path: 'manage',
        component: ManageHome,
        meta: {
          requiresAuthentication: true,
          title: "Management",
          icon: "mdi-home",
        },
        children: [
          {
            path: '',
            name: 'dashboard',
            component: Dashboard,
            meta: {
              requiresAuthentication: true,
              title: "Management",
              hideMenu: true
            },
          },
          {
            path: 'apps',
            name: 'apps',
            component: Apps,
            meta: {
              requiresAuthentication: true, title: "Apps",
              icon: "mdi-application",
              hideMenu: false
            },
          },
          {
            path: "apps/:id",
            name: "app-details",
            component: AppDetails,
            meta: {
              requiresAuthentication: true, pageTitle: "App {{pageTitle}}", pageTitleFallback: "App ..."
            }
          },
          {
            path: 'services',
            name: 'services',
            component: Services,
            meta: {
              requiresAuthentication: true, title: "Services",
              icon: "mdi-api",
              hideMenu: false
            },
          },
          {
            path: "services/:id",
            name: "service-details",
            component: ServiceDetails,
            meta: {
              requiresAuthentication: true, pageTitle: "Service {{pageTitle}}", pageTitleFallback: "Service ..."
            }
          },
          {
            path: "apps/:id/add-service-to-app",
            name: "add-service-to-app",
            component: AddServiceToApp,
            meta: {
              stages: [Stages.DEVELOP, Stages.STAGING],
              requiresAuthentication: true, pageTitle: "Add Services To {{pageTitle}}", pageTitleFallback: "Add Service To App",
              hideMenu: hideWorkInProgress
            }
          },
          {
            path: "services/:id/add-service-to-service",
            name: "add-service-to-service",
            component: AddServiceToService,
            meta: {
              stages: [Stages.DEVELOP, Stages.STAGING],
              requiresAuthentication: true, pageTitle: "Add Services To {{pageTitle}}", pageTitleFallback: "Add Service To Service",
              hideMenu: hideWorkInProgress
            }
          },
          {
            path: 'users',
            name: 'users',
            component: Users,
            meta: {
              requiresAuthentication: true, title: "Users",
              icon: "mdi-account",
              hideMenu: hideWorkInProgress
            },
          },
          {
            path: 'userpools',
            name: 'userpools',
            component: UserPools,
            meta: {
              requiresAuthentication: true, title: "User Pools",
              icon: "mdi-account-multiple",
              hideMenu: hideWorkInProgress
            },
          },
          {
            path: 'devices',
            name: 'devices',
            component: Devices,
            meta: {
              requiresAuthentication: true, title: "Devices",
              icon: "mdi-cellphone-link",
              hideMenu: hideWorkInProgress
            },
            children: [
              {
                path: 'changeapp',
                name: 'changeapp',
                component: ChangeApp,
                meta: {
                  requiresAuthentication: true,
                  hideMenu: hideWorkInProgress
                },
              },
              {
                path: 'addapp',
                name: 'addapp',
                component: AddApp,
                meta: {
                  requiresAuthentication: true,
                  hideMenu: hideWorkInProgress
                },
              },
            ]
          },
          {
            path: 'addbusinessmodel',
            name: 'addbusinessmodel',
            component: AddBusinessModel,
            meta: {
              requiresAuthentication: true,
              hideMenu: hideWorkInProgress
            },
          },
          // {
          //   path: 'services',
          //   name: 'services',
          //   component: ServicesAll,
          //   meta: {
          //     requiresAuthentication: true,
          //     hideMenu: hideWorkInProgress
          //   },
          // },
          
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
    meta: {
      requiresAnonymous: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ './views/Register.vue'),
    meta: {
      requiresAnonymous: true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

router.beforeEach((to, from, next) => {
  const token = getToken();

  if (!validOnCurrentStage(to)) {
    if(!from.matched.length){
      next({ path: "/" });
    } else {
      next(false);
    }
    return;
  }
  
  if (to.matched.some(m => m.meta.requiresAuthentication)) {
    if (!token) {
      next({ path: '/login', query: { 'redirect_to': to.path } });
    } else {
      next();
    }
  } else if (to.matched.some(m => m.meta.requiresAnonymous)) {
    if (token) {
      //next('dashboard'); // WTH?
      next();
    } else {
      next();
    }
  } else {
    next();
  }
});

function validOnCurrentStage(to: Route): boolean {
  let requirementNotMet = to.matched.some(x => x.meta && x.meta.stages && !x.meta.stages.find((stage: string) => stage === ENVIRONMENT));
  return !requirementNotMet;
}

export default router;