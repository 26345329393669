




import { Component, Vue } from 'vue-property-decorator';
import ServicesList from '@/components/ServicesList.vue';

@Component({
  components: {
    ServicesList
  }
})
export default class Services extends Vue {
  public headers: any[] = [
    // { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    // { text: 'Price per call', value: 'pricePerCall' },
    // { text: 'Secret', value: 'secret' }
  ];

  public properties: any[] = [
    { name: 'Name', key: 'name', type: 'string' },
    // { name: 'Price per call', key: 'pricePerCall', type: 'number' },
    { name: 'Released', key: 'released', type: 'boolean' },
  ];
}
