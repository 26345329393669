









import { Component, Vue } from "vue-property-decorator";
import AppList from "@/components/AppList.vue";

@Component({
  components: {
    AppList
  },
})
export default class Apps extends Vue {
  headers: any[] = [
    { text: "Name", value: "name" },
  ];

  properties: any[] = [{ name: "Name", key: "name", type: "string" }];
}
