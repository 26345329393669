









import { Component, Vue } from "vue-property-decorator";
import DevicesList from "@/components/DevicesList.vue";

@Component({
  components: {
    DevicesList,
  },
})
export default class Devices extends Vue {
  headers: any[] = [
    { text: "ID", value: "id" },
    { text: "Device Pool Id", value: "devicePoolId" },
    { text: "Name", value: "name" },
    { text: "Serial Number", value: "serialNumber" },
    { text: "Description", value: "description" },
    { text: "Location", value: "location" },
    { text: "Position", value: "position" },
    { text: "Delete", value: "deletedDate" },
  ];

  properties: any[] = [
    { name: "Name", key: "name", type: "string" },
    { name: "Serial Number", key: "serialNumber", type: "string" },
    { name: "Description", key: "description", type: "string" },
    { name: "Location", key: "location", type: "string" },
    { name: "Position", key: "position", type: "string" },
  ];
}
