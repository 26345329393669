var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"clipped":"","app":"","dark":"","width":"280"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.availableItems),function(item){return _c('div',{key:item.meta.title},[(!item.children)?_c('v-list-item',{attrs:{"to":'/' + item.path,"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.meta.icon))])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(item.meta.title)+" ")])],1):_vm._e(),(!!item.children)?_c('v-list-group',{attrs:{"disabled":"","value":true,"append-icon":"","color":"white","to":item.path},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('router-link',{staticStyle:{"color":"white","text-decoration":"none","width":"100%"},attrs:{"to":'/' + item.path}},[_c('v-list-item-action',{staticStyle:{"padding-left":"16px","vertical-align":"bottom"}},[_c('v-icon',[_vm._v(_vm._s(item.meta.icon))])],1),_c('v-list-item-content',{staticClass:"d-inline-block",staticStyle:{"vertical-align":"text-bottom"}},[_vm._v(" "+_vm._s(item.meta.title)+" ")])],1)]},proxy:true}],null,true)},_vm._l((item.children),function(sublink){return _c('v-list-item',{key:sublink.meta.title,staticClass:"v-list-item__dense",attrs:{"to":'/' +
                (item.path && item.path.length ? item.path + '/' : '') +
                sublink.path,"link":""}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(sublink.meta.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(sublink.meta.title))])],1)}),1):_vm._e()],1)}),0)],1),_c('v-app-bar',{staticClass:"primary",attrs:{"clipped-left":"","dark":"","hide-on-scroll":true,"app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title || "CloudNuggets"))]),_c('div',{staticClass:"flex-grow-1"}),_c('v-spacer'),(!_vm.isAuthenticated)?_c('v-btn',{attrs:{"icon":"","to":"/login","link":""}},[_c('v-icon',[_vm._v("mdi-login")])],1):_vm._e(),_vm._l((_vm.pageToolbarItems),function(menuItem){return _c('v-tooltip',{key:menuItem.name,attrs:{"bottom":"","disabled":menuItem.disabled || !menuItem.tooltip,"open-delay":"750"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":!!menuItem.icon,"to":menuItem.path,"disabled":menuItem.disabled},on:{"click":function (e) { return menuItem.action && menuItem.action(e); }}},on),[(!!menuItem.icon)?_c('v-icon',[_vm._v(_vm._s(menuItem.icon))]):_vm._e(),(!menuItem.icon)?_c('span',[_vm._v(_vm._s(menuItem.name))]):_vm._e()],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(menuItem.tooltip)}})])}),(_vm.isAuthenticated)?_c('v-menu',{attrs:{"offset-y":"","right":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[(_vm.isAuthenticated)?_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',[_vm._v("mdi-logout")]),_c('v-list-item-title',[_vm._v("Logout")])],1):_vm._e()],1)],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }