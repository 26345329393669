




























































































































































import { Component, Vue } from "vue-property-decorator";
import {
  IAppsClient,
  IServicesClient,
  AppDto,
  ServiceDto,
  AddApp,
  AddService,
} from "@/services/CloudNuggetsApiClient";
import { API_ENDPOINT } from "@/services/Environment";
//import axios from "axios";
import { axios } from "@/services/HttpService";
import { Inject } from "inversify-props";

@Component
export default class Dashboard extends Vue {
  routeapps: string = "/apps";
  routeservices: string = "/services";
  apps: AppDto[] = [];
  services: ServiceDto[] = [];
  loading = { apps: false, services: false, addApp: false, addService: false };

  isAddAppDialogVisible = false;
  addAppData = { name: "" };
  
  isAddServiceDialogVisible = false;
  addServiceData = { name: "" };

  @Inject() private appsClient: IAppsClient;
  @Inject() private servicesClient: IServicesClient;

  async created() {
    await this.load();
  }

  private showAddApp() {
    this.addAppData = { name: "" };
    this.isAddAppDialogVisible = true;
  }

  private async addApp() {
    this.loading.addApp = true;
    try {
      const newApp = await this.appsClient.addApp(
        new AddApp({
          name: this.addAppData.name,
        })
      );

      this.apps = [...this.apps, newApp].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );

      this.isAddAppDialogVisible = false;
    
      this.$router.push( { name: "app-details", params: { id: `${newApp.entityId}`} });
    } catch (error) {
    } finally {
      this.loading.addApp = false;
    }
  }

  private showAddService() {
    this.addServiceData = { name: "" };
    this.isAddServiceDialogVisible = true;
  }

  private async addService() {
    this.loading.addService = true;
    try {
      const newService = await this.servicesClient.addService(
        new AddService({
          name: this.addServiceData.name,
          released: false
        })
      );

      this.services = [...this.services, newService].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );

      this.isAddServiceDialogVisible = false;
    
      this.$router.push( { name: "service-details", params: { id: `${newService.entityId}`} });
    } catch (error) {
    } finally {
      this.loading.addService = false;
    }
  }

  async load() {
    await Promise.all([this.loadApps(), this.loadServices()]);
  }

  async loadServices() {
    this.loading.services = true;

    //services request
    try {
      this.services = await this.servicesClient.getServices();
      this.services = this.services.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    } catch (error) {
      console.error(error);
      // blank
    } finally {
      this.loading.services = false;
    }
  }

  async loadApps() {
    this.loading.apps = true;

    //apps request
    try {
      this.apps = await this.appsClient.getApps();
      this.apps = this.apps.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    } catch (error) {
      console.error(error);
      // blank
    } finally {
      this.loading.apps = false;
    }
  }
}
