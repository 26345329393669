

































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { clearToken, API_ENDPOINT } from "@/services/Environment";
import {
  ServiceDto,
  AddService,
  UpdateService,
  IServicesClient,
} from "@/services/CloudNuggetsApiClient";
import { axios } from "@/services/HttpService";
import { Inject } from "inversify-props";

@Component({
  components: {},
})
export default class ServicesList extends Vue {
  @Prop()
  title!: string;

  // @Prop()
  // route!: string

  @Prop()
  headers!: any[];

  @Prop()
  properties!: any[];

  get allHeaders(): any[] {
    return [
      ...this.headers,
      { text: "Actions", value: "action", sortable: false },
    ];
  }

  get itemDescription(): string {
    return this.title.substr(0, this.title.length - 1);
  }

  items: ServiceDto[] = [];
  item: ServiceDto = null;

  search: string = "";

  isLoading: boolean = false;
  isAddDialogVisible: boolean = false;
  isUpdateDialogVisible: boolean = false;
  isDeleteDialogVisible: boolean = false;

  @Inject()
  private servicesClient: IServicesClient;

  async created() {
    await this.load();
  }

  public open(e: any) {
    console.dir(e);
  }

  async load() {
    this.isLoading = true;
    try {
      // const response = await get(this.route)
      // this.items = response.data
      this.items = (await this.servicesClient.getServices()).sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  showAddDialog() {
    this.item = new ServiceDto();
    this.isAddDialogVisible = true;
  }

  showUpdateDialog(item: any) {
    this.item = { ...item };
    this.isUpdateDialogVisible = true;
  }

  showDeleteDialog(item: any) {
    this.item = item;
    this.isDeleteDialogVisible = true;
  }

  async addItem() {
    this.isLoading = true;
    // const response = await post(this.route, this.item)
    // const item = response.data
    var item = await this.servicesClient.addService(
      new AddService({
        ...this.item
      })
    );
    this.items = [...this.items, item];
    this.isAddDialogVisible = false;
    this.isLoading = false;
    
    this.$router.push( { name: "service-details", params: { id: `${item.entityId}`} });
  }

  async updateItem() {
    this.isLoading = true;
    // const response = await put(`${this.route}/${this.item.id}`, this.item)
    await this.servicesClient.updateService(
      this.item.entityId,
      new UpdateService({
        ...this.item
      })
    );

    const existingItem = this.items.find(
      (i) => i.entityId === this.item.entityId
    );
    for (const property of this.properties) {
      (<any>existingItem)[property.key] = (<any>this.item)[property.key];
    }

    this.isUpdateDialogVisible = false;
    this.isLoading = false;
  }

  async deleteItem() {
    this.isLoading = true;
    // await del(`${this.route}/${this.item.id}`)
    await this.servicesClient.deleteService(this.item.entityId);

    this.items = this.items.filter((i) => i.entityId !== this.item.entityId);
    this.isDeleteDialogVisible = false;
    this.isLoading = false;
  }
}
