









































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import MarkdownDisplay from "@/components/MarkdownDisplay.vue";
import SwaggerDisplay from "@/components/SwaggerDisplay.vue";
import { StatusIndicator } from "vue-status-indicator";
import {
  IServicesClient,
  ServiceDto,
  ICloudNuggetClient,
  CloudNuggetClient,
  PingDto,
} from "@/services/CloudNuggetsApiClient";
import { API_ENDPOINT } from "@/services/Environment";
import { axios } from "@/services/HttpService";
import { IAppbarService } from "@/services/AppbarService";
import { Inject } from "inversify-props";

class SelectModel<T> {
  constructor(text: string, value: T) {
    this.text = text;
    this.value = value;
  }
  public value: T;
  public text: string;
}

@Component({
  components: {
    MarkdownDisplay,
    SwaggerDisplay,
    StatusIndicator,
  },
})
export default class ServiceInfos extends Vue {
  public tabs: any = null;
  public mkReadme: string = null;
  public mkChangelog: string = null;
  // public environment: string = "";
  public pingcheck: boolean = false;
  public statuscheck: number;

  public openApiDocument: { [key: string]: any } = null;
  public version: string = null;
  public service: ServiceDto = null;

  public stageUrls: SelectModel<string>[] = [];

  set pageTitle(value: string) {
    this._pageTitle = value;
    this.appbarService.updatePageTitle(this._pageTitle);
  }
  get pageTitle(){ return this._pageTitle; }

  private _pageTitle: string = null;
  private ping: PingDto = null;
  private url: string = null;
  private apiVersions: string[] = ["1"];
  private apiVersion: string = null;
  private openApiUrl: string = null;

  @Inject()
  private servicesClient: IServicesClient;

  @Inject()
  private appbarService: IAppbarService;

  private cloudNuggetsClient: ICloudNuggetClient;

  private loading = { stage: false, service: false };
  private entityId: string;

  public async checkServer() {
    try {
      await this.cloudNuggetsClient.getPing();
      this.pingcheck = true;
    } catch (error) {
      this.pingcheck = false;
    }
  }

  private startServerCheckTimer() {
    this.checkServer();
    this.statuscheck = setInterval(() => {
      this.checkServer();
    }, 10000);
  }

  private beforeDestroy() {
    clearInterval(this.statuscheck);
  }

  private async created() {
    this.entityId = this.$route.params.id;
    await this.load();

    this.startServerCheckTimer();
  }

  private async load() {
    this.loading.service = true;
    try {
      this.service = await this.getServiceInfo();
      this.pageTitle =this.service.name;

      this.stageUrls = [
        new SelectModel<string>("Production", this.service.baseUrlProduction),
        new SelectModel<string>("Staging", this.service.baseUrlStaging),
      ];
      this.url = this.stageUrls[0].value || "invalid";

      await this.loadStageData();
    } finally {
      this.loading.service = false;
    }
  }

  private async getServiceInfo(){
    const releasedService = await this.servicesClient.getReleasedService(this.entityId);
    
    return releasedService;
  }

  private async loadStageData() {
    this.loading.stage = true;

    try {
      this.cloudNuggetsClient = new CloudNuggetClient(this.url, axios);

      this.mkReadme = await this.cloudNuggetsClient.getReadme();
      this.mkChangelog = await this.cloudNuggetsClient.getChangelog();

      this.ping = await this.cloudNuggetsClient.getPing();

      this.apiVersions = this.ping.apiVersions;
      this.version = this.ping.version;

      // version not hardcoded!
      const latestApiVersion = this.apiVersions[this.apiVersions.length - 1];
      this.apiVersions.push("2");
      if (!this.apiVersion || this.apiVersions.indexOf(this.apiVersion) == -1) {
        this.apiVersion = latestApiVersion;
      }

      await this.loadApiData();
      this.$forceUpdate();
      
      await this.checkServer();
    } catch (error) {
      this.apiVersions = ["1"];
    } finally {
      this.loading.stage = false;
    }
  }

  private async loadApiData() {
    this.openApiDocument = null;

    try {
      this.openApiUrl = `${this.url}/s1/cloud-nugget/openapi/${this.apiVersion}`;

      this.openApiDocument = await this.cloudNuggetsClient.getOpenApi(
        this.apiVersion
      );
    } catch (error) {}
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
