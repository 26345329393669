








import Vuetify, { VSnackbar } from 'vuetify/lib';
import { Component, Vue, Ref } from "vue-property-decorator";
import { API_ENDPOINT, getToken } from "@/services/Environment";
import { axios } from "@/services/HttpService";
//import axios from "axios";
import router from "./router";
import { ENVIRONMENT } from "./services/Config";
import { VueConstructor } from 'vue';

@Component({
  components: {},
})
export default class App extends Vue {
  @Ref() public readonly errorSnackbar!: VueConstructor;
  @Ref() public readonly successSnackbar!: VueConstructor;

  public successMessage: string = null;
  public successIcon: string = null;
  public showSuccessSnackbar: boolean = false;

  public errorMessage: string = null;
  public errorIcon: string = null;
  public showErrorSnackbar: boolean = false;

  async created() {
    this.$root.$on("notify:success", (e: any) => {
        this.successMessage = e.message;
        this.successIcon = e.icon;
        this.showSuccessSnackbar = true;
      });

      this.$root.$on("notify:error", (e: any) => {
        this.errorMessage = e.message;
        this.errorIcon = e.icon;
        this.showErrorSnackbar = true;
      });
  }
}
