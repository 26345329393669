export default function retry(action: () => any, retryCount: number) {
    let count = retryCount;
    let intervalHandle = setInterval(() => {
        try {
            let result = action();
            clearInterval(intervalHandle);

            return result;
        } catch (error) {
            if (count == 0){
                clearInterval(intervalHandle);
            } else {
                count--;
            }
        }
    }, 100);
}