







































































































































































































import { Component, Vue } from "vue-property-decorator";
import { axios } from "@/services/HttpService";
import { IUserPoolsClient, AddUserPool, UpdateUserPool, UserPoolDto, UserDto } from "@/services/CloudNuggetsApiClient";
import { API_ENDPOINT } from "@/services/Environment";
import { Inject } from 'inversify-props';

@Component({
  components: {},
})
export default class UserPools extends Vue {
  public headers: any[] = [
    { text: "ID", value: "id" },
    { text: "Name", value: "name" },
    { text: "Pool ID", value: "userPoolId" },
    { text: "Account Id", value: "accountId" },
    { text: "Delete", value: "deletedDate" },
  ];

  public userHeaders: any[] = [
    { text: "ID", value: "id" },
    { text: "First Name", value: "firstName" },
    { text: "Last Name", value: "lastName" },
  ];

  public properties: any[] = [
    { name: "Name", key: "name", type: "string" },
    { name: "User Pool", key: "userPoolId", type: "string" },
  ];

  public route: string = "/user-pools";
  public items: UserPoolDto[] = [];
  public userPoolUsers: UserDto[] = [];

  public item: UserPoolDto = null;

  public search: string = "";

  public isLoading: boolean = false;
  public isAddDialogVisible: boolean = false;
  public isUpdateDialogVisible: boolean = false;
  public isDeleteDialogVisible: boolean = false;

  @Inject()
  private userPoolsClient: IUserPoolsClient;

  public async created() {
    await this.load();
  }

  public showAddDialog() {
    this.item = new UserPoolDto();
    this.isAddDialogVisible = true;
  }

  public showUpdateDialog(item: any) {
    this.item = { ...item };
    this.isUpdateDialogVisible = true;
  }

  public showDeleteDialog(item: any) {
    this.item = item;
    this.isDeleteDialogVisible = true;
  }

  public showUserDialog(item: any) {
    // dummy
    // this.item = item;
    // this.isDeleteDialogVisible = true;
  }

  public async addItem() {
    this.isLoading = true;
    // const response = await post(this.route, this.item);
    // const item = response.data;

    // TODO: values
    const item = await this.userPoolsClient.addUserPool(
      new AddUserPool({
        ...this.item,
        name: "dummy name",
        appId: 0
      })
    );

    this.items = [...this.items, item];
    this.isAddDialogVisible = false;
    this.isLoading = false;
  }

  public async updateItem() {
    this.isLoading = true;
    // const response = await put(`${this.route}/${this.item.id}`, this.item);

    // TODO: values
    await this.userPoolsClient.updateUserPool(this.item.id, new UpdateUserPool({
      ...this.item,
      id: this.item.id,
      name: "dummy name"
    }));

    const existingItem = this.items.find((i) => i.id === this.item.id);
    for (const property of this.properties) {
      (<any>existingItem)[property.key] = (<any>this.item)[property.key];
    }

    this.isUpdateDialogVisible = false;
    this.isLoading = false;
  }

  public async deleteItem() {
    this.isLoading = true;
    // await del(`${this.route}/${this.item.id}`);
    await this.userPoolsClient.deleteUserPool(this.item.id);

    this.items = this.items.filter((i) => i.id !== this.item.id);
    this.isDeleteDialogVisible = false;
    this.isLoading = false;
  }

  private async load() {
    this.isLoading = true;
    
    try {
      // const response = await get(this.route);
      // this.items = response.data;
      this.items = await this.userPoolsClient.getUserPools();

      for (const userPool of this.items) {
        // const responseUser = await get(`/users/pool/${userPool.id}`);
        const users = await this.userPoolsClient.getUsersAll(userPool.id)
        Vue.set(this.userPoolUsers, userPool.id, users);
      }
    } catch (error) {
      // blank
    } finally {
      this.isLoading = false;
    }
  }
}
