


























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { clearToken, API_ENDPOINT } from "@/services/Environment";
import { axios } from "@/services/HttpService";
import {
  DeviceDto,
  AddDevice,
  IDevicesClient,
} from "@/services/CloudNuggetsApiClient";
import { Inject } from 'inversify-props';

@Component({
  components: {},
})
export default class DevicesList extends Vue {
  @Prop()
  title!: string;

  // @Prop()
  // route!: string

  @Prop()
  headers!: any[];

  @Prop()
  properties!: any[];

  get allHeaders(): any[] {
    return [
      ...this.headers,
      { text: "Actions", value: "action", sortable: false },
    ];
  }

  get itemDescription(): string {
    return this.title.substr(0, this.title.length - 1);
  }

  items: DeviceDto[] = [];
  item: DeviceDto = null;

  search: string = "";

  isLoading: boolean = false;
  isAddDialogVisible: boolean = false;
  isUpdateDialogVisible: boolean = false;
  isDeleteDialogVisible: boolean = false;

  @Inject()
  private devicesClient: IDevicesClient;

  async created() {
    await this.load();
  }

  async load() {
    this.isLoading = true;
    try {
      // const response = await get(this.route)
      // this.items = response.data
      this.items = await this.devicesClient.getDevices();
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  }

  showAddDialog() {
    this.item = <any>{};
    this.isAddDialogVisible = true;
  }

  showUpdateDialog(item: any) {
    this.item = { ...item };
    this.isUpdateDialogVisible = true;
  }

  showDeleteDialog(item: any) {
    this.item = item;
    this.isDeleteDialogVisible = true;
  }

  async addItem() {
    this.isLoading = true;
    // const response = await post(this.route, this.item)
    // const item = response.data
    const item = await this.devicesClient.addDevice(
      new AddDevice({
        ...this.item,
      })
    );

    this.items = [...this.items, item];
    this.isAddDialogVisible = false;
    this.isLoading = false;
  }

  async updateItem() {
    this.isLoading = true;
    // const response = await put(`${this.route}/${this.item.id}`, this.item)
    await this.devicesClient.updateDevice(this.item.id, this.item);

    const existingItem = this.items.find((i) => i.id === this.item.id);
    for (const property of this.properties) {
      (<any>existingItem)[property.key] = (<any>this.item)[property.key];
    }

    this.isUpdateDialogVisible = false;
    this.isLoading = false;
  }

  async deleteItem() {
    this.isLoading = true;
    // await del(`${this.route}/${this.item.id}`)
    await this.devicesClient.deleteDevice(this.item.id);

    this.items = this.items.filter((i) => i.id !== this.item.id);
    this.isDeleteDialogVisible = false;
    this.isLoading = false;
  }
}
