import { VueConstructor } from 'vue';
import retry from './retry';

export interface INotificationService {
    success(message: string, icon?: string): void;
    error(message: string, icon?: string): void;
}
export class NotificationService implements INotificationService {
    constructor(private appFactory: () => Vue) {
        
    }
    success(message: string, icon?: string): void {
        retry(() => this.appFactory().$root.$emit("notify:success", {message, icon}), 20);
    }

    error(message: string, icon?: string): void {
        retry(() => this.appFactory().$root.$emit("notify:error", {message, icon}), 20);
    }
}