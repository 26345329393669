enum BillingPeriod {
    Free = <any>"free",
    OneTime = <any>"one-time",
    Daily = <any>"daily",
    Weekly = <any>"weekly",
    Monthly = <any>"monthly",
    Yearly = <any>"yearly"
}

enum BillingTime {
    Begin = <any>"begin",
    End = <any>"end"
}

enum YesNo {
    No = <any>"no",
    Yes = <any>"yes"
}

enum ConsumptionGroupLevel {
    global = <any>"global",
    tenant = <any>"tenant",
    user = <any>"user",
    payload = <any>"payload"
}

export interface IBillingAmount {
    country?: string;
    currency: string;
    netamount: number;
}

export interface IBillingDetails {
    name: string;
    amount: IBillingAmount[];
}

export interface IBasicBillingDetails extends IBillingDetails {

}

export interface IConsumptionBillingDetails extends IBillingDetails {
    selector?: string;
    grouplevel?: ConsumptionGroupLevel;
    unit: string;
    includedunits?: string; // TODO: why not number?!
    billingincrement?: number; // TODO: why not number?!   
}

export interface ICharge {
    basic?: IBasicBillingDetails;
    consumption?: IConsumptionBillingDetails;

    [key: string]: IBillingDetails;
}


export interface IPcf {
    name: string;
    description: string;
    billingperiod: BillingPeriod;
    billingtime: BillingTime;
    billingcn: string;
    minimumcontracttime: string; // TODO: why not number?!
    testperiod: string; // TODO: why not number?!
    contracttext: string;
    moreinformation: string;
    maximumcontracts: string; // TODO: why not number?!
    validfrom: string;
    validto: string;
    private: YesNo; // TODO: why not boolean?!

    charges: ICharge[];
}

export interface ICostInfo {
    amount: number;
    currency: string;
    chargeType: string;
    chargeUnit?: string;
}

export interface IPcfService {
    parse(pcfJson: string): IPcf;
    getMinCostInfo(pcf: IPcf): ICostInfo;
}

export class PcfService implements IPcfService {
    public parse(pcfJson: string): IPcf {
        if (!pcfJson){
            return null;
        }

        return JSON.parse(pcfJson);
    }

    public getMinCostInfo(pcf: IPcf): ICostInfo {
        let minAmount = -1;
        let minCurrency = <string>null;
        let minChargeTypeKey = <string>null;
        let minChargeUnit = <string>null;

        for (let charge of pcf.charges) {
            for (var chargeTypeKey in charge) {
                let chargeType = charge[chargeTypeKey];
                if (!chargeType) {
                    continue;
                }

                for (let amount of chargeType.amount) {
                    if (minAmount === -1 ||
                        amount.netamount < minAmount) {
                        minAmount = amount.netamount;
                        minCurrency = amount.currency;
                        minChargeTypeKey = chargeTypeKey;
                        minChargeUnit = (<IConsumptionBillingDetails>chargeType).unit;
                    }
                }
            }
        }

        if (minAmount === -1) {
            return null;
        }

        return {
            amount: minAmount,
            currency: minCurrency,
            chargeType: minChargeTypeKey,
            chargeUnit: minChargeUnit
        };
    }
}