







































import { Component, Vue } from "vue-property-decorator";
import { IServicesClient, ServiceDto } from "@/services/CloudNuggetsApiClient";
import { API_ENDPOINT } from "@/services/Environment";
import { axios } from "@/services/HttpService";
import { Inject } from "inversify-props";

@Component({
  components: {},
})
export default class ServicesOverview extends Vue {
  routeservices: string = "/services";
  services: ServiceDto[] = [];
  isLoading: boolean = false;

  @Inject()
  private servicesClient: IServicesClient;

  async created() {
    await this.load();
  }

  async load() {
    this.isLoading = true;

    //services request
    try {
      this.services = (
        await this.servicesClient.getReleasedServices()
      ).sort((a, b) => a.name.localeCompare(b.name)); // should be done on server
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
