



































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import MarkdownDisplay from "@/components/MarkdownDisplay.vue";
import SwaggerDisplay from "@/components/SwaggerDisplay.vue";
import { StatusIndicator } from "vue-status-indicator";
import {
  IServicesClient,
  ServiceDto,
  ICloudNuggetClient,
  CloudNuggetClient,
  PingDto,
  AppDto,
  IAppsClient,
  AppKeyDto,
  AppServiceDependencyDto,
  UpdateApp,
  IUserPoolsClient,
} from "@/services/CloudNuggetsApiClient";
import { API_ENDPOINT } from "@/services/Environment";
import { axios } from "@/services/HttpService";
import { Inject } from "inversify-props";
import PriceInfoDialog from '@/components/PriceInfoDialog.vue';
import { PriceInfoDialogData } from '@/components/PriceInfoDialog.vue';
import { INotificationService } from '@/services/NotificationService';
import { IAppbarService } from '@/services/AppbarService';

@Component({
  components: {
    MarkdownDisplay,
    SwaggerDisplay,
    StatusIndicator,
    PriceInfoDialog
  },
})
export default class AppDetails extends Vue {
  private _pageTitle: string = null;
  set pageTitle(value: string) {
    this._pageTitle = value;
    this.appbarService.updatePageTitle(this._pageTitle);
  }
  get pageTitle(){ return this._pageTitle; }

  public app: AppDto = null;
  public secrets: AppKeyDto[] = [];
  public services: AppServiceDependencyDto[] = [];

  public priceInfoData: PriceInfoDialogData = null;

  private pageToolbarItems: any[] = [
    { name: "Rename", icon: "mdi-pencil", tooltip: "Rename", action: () => this.showUpdateDialog() },
  ];

  isUpdateDialogVisible: boolean = false;
  renameData = { title: "", name: "" };

  @Inject()
  private notificationService: INotificationService;
  
  @Inject()
  private appbarService: IAppbarService;

  @Inject()
  private appsClient: IAppsClient;
  
  private loading = {
    app: false,
    secret: false,
    appServices: false,
    rename: false,
  };
  private entityId: string;

  public secretCopied() {
    this.notificationService.success("Secret copied to clipboard");
  }

  private async created() {
    this.entityId = this.$route.params.id;
    this.appbarService.updateToolbarItems(this.pageToolbarItems);

    await Promise.all([
      this.loadApp(),
      this.loadSecret(),
      this.loadAppServices(),
    ]);
  }

  showUpdateDialog() {
    this.renameData = { title: this.app.name, name: this.app.name };
    this.isUpdateDialogVisible = true;
  }

  public async removeServiceDependendy(serviceDependency: AppServiceDependencyDto) {
    try {
      await this.appsClient.deleteAppService(this.app.entityId, serviceDependency.entityId);

      this.services.splice(this.services.indexOf(serviceDependency), 1);

      this.notificationService.success(`'${serviceDependency.name}' removed from '${this.app.name}'`);
    } catch (error) {
      console.error(error);

      this.notificationService.error(`Could not remove '${serviceDependency.name}' from '${this.app.name}'`);
    }
  }

  private async rename() {
    try {
      this.loading.rename = true;
      await this.appsClient.updateApp(
        this.entityId,
        new UpdateApp({
          name: this.renameData.name
        })
      );
      this.pageTitle = this.renameData.name;
      this.isUpdateDialogVisible = false;
    } catch (error) {
    } finally {
      this.loading.rename = false;
    }
  }
  
  public async showPriceInfoDialog(service: AppServiceDependencyDto) {
    this.priceInfoData = { serviceEntityId: service.entityId, productKey: service.productKey, visible: true };
  }

  private async loadApp() {
    this.loading.app = true;
    try {
      this.app = await this.appsClient.getApp(this.entityId);
      this.pageTitle = this.app.name;
    } finally {
      this.loading.app = false;
    }
  }

  private async loadSecret() {
    this.loading.secret = true;
    try {
      let keys = await this.appsClient.getAppKeys(this.entityId);
      this.secrets = keys;
    } finally {
      this.loading.secret = false;
    }
  }

  private async loadAppServices() {
    this.loading.appServices = true;
    try {
      this.services = await this.appsClient.getAppServices(this.entityId);
    } finally {
      this.loading.appServices = false;
    }
  }
}
