






























































import { Component, Vue } from "vue-property-decorator";
import { axios } from "@/services/HttpService";
import { IAppsClient } from "@/services/CloudNuggetsApiClient";
import { API_ENDPOINT } from "@/services/Environment";
import { Inject } from 'inversify-props';

@Component({
  components: {},
})
export default class AddApp extends Vue {
  routeapps: string = "/apps";
  items: any[] = [];
  item: any = {};
  apps: any = {};
  isLoading: boolean = false;

  @Inject()
  private appsClient: IAppsClient;

  public async created() {
    await this.load();
  }

  private async load() {
    this.isLoading = true;

    //apps request
    try {
      // const response = await get(this.routeapps);
      // this.items = response.data;
      this.items = await this.appsClient.getApps();

      for (const apps of this.items) {
        Vue.set(this.apps, apps.id, apps);
      }
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  }

  public data() {
    return {
      dialog: false,
    };
  }
}
