





























































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { clearToken, API_ENDPOINT } from "@/services/Environment";
import { axios } from "@/services/HttpService";
import { Inject } from "inversify-props";
import {
  UpdateApp,
  AppDto,
  AddApp,
  IAppsClient,
} from "@/services/CloudNuggetsApiClient";
import { CombinedVueInstance } from "vue/types/vue";

@Component({})
export default class AppList extends Vue {
  @Prop()
  title!: string;

  // @Prop()
  // route!: string

  @Prop()
  headers!: any[];

  @Prop()
  properties!: any[];

  get allHeaders(): any[] {
    return [
      ...this.headers,
      { text: "Actions", value: "action", sortable: false },
    ];
  }

  get itemDescription(): string {
    return this.title.substr(0, this.title.length - 1);
  }

  items: AppDto[] = [];
  item: AppDto = null;

  search: string = "";

  isLoading: boolean = false;
  isAddDialogVisible: boolean = false;
  isUpdateDialogVisible: boolean = false;
  isDeleteDialogVisible: boolean = false;

  @Inject()
  private appsClient: IAppsClient;

  public open(e: any) {
    console.dir(e);
  }

  async created() {
    await this.load();
  }

  async load() {
    this.isLoading = true;
    try {
      // const response = await get(this.route)
      // this.items = response.data
      this.items = (await this.appsClient.getApps())
        .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  }

  showAddDialog() {
    this.item = new AppDto();
    this.isAddDialogVisible = true;
  }

  showUpdateDialog(item: any) {
    this.item = { ...item };
    this.isUpdateDialogVisible = true;
  }

  showDeleteDialog(item: any) {
    this.item = item;
    this.isDeleteDialogVisible = true;
  }

  async addItem() {
    this.isLoading = true;
    const item = await this.appsClient.addApp(new AddApp(this.item));

    this.items = [...this.items, item]
        .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    this.isAddDialogVisible = false;
    this.isLoading = false;
    
    this.$router.push( { name: "app-details", params: { id: `${item.entityId}`} });
  }

  async updateItem() {
    this.isLoading = true;
    // const response = await put(`${this.route}/${this.item.id}`, this.item)
    await this.appsClient.updateApp(
      this.item.entityId,
      new UpdateApp({
        ...this.item
      })
    );

    const existingItem = this.items.find(
      (i) => i.entityId === this.item.entityId
    );
    for (const property of this.properties) {
      (<any>existingItem)[property.key] = (<any>this.item)[property.key];
    }

    this.isUpdateDialogVisible = false;
    this.isLoading = false;
  }

  async deleteItem() {
    this.isLoading = true;
    // await del(`${this.route}/${this.item.id}`)
    await this.appsClient.deleteApp(this.item.entityId);

    this.items = this.items.filter((i) => i.entityId !== this.item.entityId);
    this.isDeleteDialogVisible = false;
    this.isLoading = false;
  }
}
